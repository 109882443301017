









































import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { formatBytes } from '@shared/util/file';
import { Textfield, Spinner, Icon, Checkbox, IconButton } from '@components/UI';
import { PollOption } from './components';
import { PollOptionModel } from './models';

@Component({
    components: { Textfield, Spinner, Icon, Checkbox, PollOption, IconButton }
})
export default class AddPoll extends Vue {
    /* Form Fields */
    private title: string = '';
    private ttl: number = 86400;
    private allowMultiple: boolean = false;
    private userOpts: boolean = false;
    private isPublic: boolean = true;
    private inStream: boolean = false;

    private pollOptions: Array<PollOptionModel> = new Array<PollOptionModel>();

    private error: string = '';
    private loading: boolean = false;
    private success: boolean = false;

    private shareUrl: string = '';

    @Action
    public createPoll: (any) => Promise<any>;

    mounted() {
        //this.pollOptions = new Array<PollOption>();
        this.pollOptions.push(new PollOptionModel());
        this.pollOptions.push(new PollOptionModel());
    }

    async onSubmit() {
        if(!this.canSubmit()) return;
        this.fancyPrint();

        try {
            this.loading = true;

            var response = await this.createPoll({
                title: this.title,
                ttl: this.ttl,
                multipleOptions: this.allowMultiple,
                usersAddOptions: this.userOpts,
                publicPoll: this.isPublic,
                postStream: this.inStream,
                options: this.pollOptions
            });

            if(!response.success)
                this.error = response.error || 'An unknown error occurred.';
            else {
                this.success = true;
                this.error = '';
                this.shareUrl = `https://fh.bizzy.live/poll/${response.poll.id}`;
            }
            
            this.loading = false;
        } catch (error) {
            console.log(error);

            this.loading = false;
            if(error.errors && error.errors.length > 0) {
                var estr = "The following errors occured:<br/><ul>";

                for(var i = 0; i < error.errors.length; i++)
                    estr += `<li><strong>${error.errors[i]}</strong></li>`;
                
                estr += "</ul>";
                this.error = estr;
            }
            else
               this.error = error.error || 'An Unknown Error Ocurred';
        }
    }

    canSubmit() {
        if(this.title.length < 5 || this.title.length > 75 || this.pollOptions.length < 2 || this.pollOptions.length > 15) return false;

        for(var i = 0; i < this.pollOptions.length; i++)
            if(this.pollOptions[i].Text.length < 5 || this.pollOptions[i].Text.length > 75)
                return false;


        return true;
    }

    fancyPrint() {
        console.log(this.isPublic);

        console.log("Listed Options: ");
        console.log("----------------");
        this.pollOptions.forEach((opt, ind) => {
            console.log(`Opt #${ind}: ${opt.Text}`);
        });
        console.log("----------------");
    }

    addOption() {
        if(this.pollOptions.length < 15)
            this.pollOptions.push(new PollOptionModel());
        return false;
    }
    removeLastOption() {
        if(this.pollOptions.length > 2)
            this.pollOptions.pop();
        return false;
    }
}




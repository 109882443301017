







import { Component, Vue, Prop } from 'vue-property-decorator';
import { Textfield, Spinner, Icon, Checkbox } from '@components/UI';
import { PollOptionModel } from '../models';

@Component({
    components: { Textfield, Spinner, Icon, Checkbox }
})
export default class PollOption extends Vue {
    @Prop({ default: '' })
    public pollOption: PollOptionModel;

    @Prop()
    public keyInd: number;

    getLabel() {
        return `Option #${this.keyInd+1}`;
    }
}

